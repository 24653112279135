import { ChangeDetectionStrategy, Component, forwardRef, ViewEncapsulation } from '@angular/core';

import { HlmDialogOverlayDirective } from './hlm-dialog-overlay.directive';
import { BrnDialogOverlayComponent, BrnDialogComponent, provideBrnDialogDefaultOptions } from 'libs/dialog/brain';

@Component({
	selector: 'hlm-dialog',
	standalone: true,
	imports: [BrnDialogOverlayComponent, HlmDialogOverlayDirective],
	providers: [
		{
			provide: BrnDialogComponent,
			useExisting: forwardRef(() => HlmDialogComponent),
		},
		provideBrnDialogDefaultOptions({
			closeOnBackdropClick: false,
		}),
	],
	template: `
		<brn-dialog-overlay hlm />
		<ng-content />
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
	exportAs: 'hlmDialog',
})
export class HlmDialogComponent extends BrnDialogComponent {}
