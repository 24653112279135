import { Directive, computed, input } from '@angular/core';
import { clsx, type ClassValue } from 'clsx';
import { BrnDialogDescriptionDirective } from 'libs/dialog/brain';
import { twMerge } from 'tailwind-merge';

@Directive({
	selector: '[hlmDialogDescription]',
	standalone: true,
	host: {
		'[class]': '_computedClass()',
	},
	hostDirectives: [BrnDialogDescriptionDirective],
})
export class HlmDialogDescriptionDirective {
	public readonly userClass = input<ClassValue>('', { alias: 'class' });
	protected _computedClass = computed(() => twMerge(clsx('text-sm text-muted-foreground', this.userClass())));
}
