import { NgComponentOutlet } from '@angular/common';
import { ChangeDetectionStrategy, Component, ViewEncapsulation, computed, inject, input } from '@angular/core';
import type { ClassValue } from 'clsx';
import { HlmDialogCloseDirective } from './hlm-dialog-close.directive';
import { BrnDialogCloseDirective, BrnDialogRef, injectBrnDialogContext } from 'libs/dialog/brain';
import { hlm } from 'libs/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

@Component({
	selector: 'hlm-dialog-content',
	standalone: true,
	imports: [NgComponentOutlet, BrnDialogCloseDirective, HlmDialogCloseDirective,FontAwesomeModule],
	host: {
		'[class]': '_computedClass()',
		'[attr.data-state]': 'state()',
	},
	template: `
		@if (component) {
			<ng-container [ngComponentOutlet]="component" />
		} @else {
			<ng-content />
		}

    @if(hasCloseButton)
      {
        <button brnDialogClose hlm>
    <fa-icon
                class="hover:text-violet-500"
                [icon]="['fadr', 'xmark']"
              ></fa-icon>
			<span class="sr-only">Close</span>
		</button>
      }
	`,
	changeDetection: ChangeDetectionStrategy.OnPush,
	encapsulation: ViewEncapsulation.None,
})
export class HlmDialogContentComponent {
	private readonly _dialogRef = inject(BrnDialogRef);
	private readonly _dialogContext = injectBrnDialogContext({ optional: true });

	public readonly state = computed(() => this._dialogRef?.state() ?? 'closed');

	public readonly component = this._dialogContext?.$component;
	private readonly _dynamicComponentClass = this._dialogContext?.$dynamicComponentClass;
  public readonly hasCloseButton = this._dialogContext?.hasCloseButton;


  /**
   *
   */
  constructor() {
    console.log(this._dialogContext)
  }
	public readonly userClass = input<ClassValue>('', { alias: 'class' });
	protected readonly _computedClass = computed(() =>
		hlm(
			'border-border grid w-full max-w-lg relative gap-4 border bg-background p-6 shadow-lg [animation-duration:200] data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-top-[2%]  data-[state=open]:slide-in-from-top-[2%] sm:rounded-lg md:w-full',
			this.userClass(),
			this._dynamicComponentClass,
		),
	);
}
