import { Directive, computed, input } from '@angular/core';
import { clsx, type ClassValue } from 'clsx';
import { BrnDialogTitleDirective } from 'libs/dialog/brain';
import { twMerge } from 'tailwind-merge';

@Directive({
	selector: '[hlmDialogTitle]',
	standalone: true,
	host: {
		'[class]': '_computedClass()',
	},
	hostDirectives: [BrnDialogTitleDirective],
})
export class HlmDialogTitleDirective {
	public readonly userClass = input<ClassValue>('', { alias: 'class' });
	protected _computedClass = computed(() =>  twMerge(clsx('text-lg font-semibold leading-none tracking-tight', this.userClass())));
}
