import { Component, computed, input } from '@angular/core';
import type { ClassValue } from 'clsx';
import { hlm } from 'libs/core';

@Component({
	selector: 'hlm-dialog-header',
	standalone: true,
	template: `
		<ng-content />
	`,
	host: {
		'[class]': '_computedClass()',
	},
})
export class HlmDialogHeaderComponent {
	public readonly userClass = input<ClassValue>('', { alias: 'class' });
	protected _computedClass = computed(() =>
		hlm('flex flex-col space-y-1.5 text-center sm:text-left', this.userClass()),
	);
}
